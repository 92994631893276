amplify-authenticator
 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}
amplify-s3-image {
  --height: auto;
  --width: 100%;
  }